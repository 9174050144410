export * from "./account";
export * from "./backoffice";
export * from "./cart";
export * from "./category";
export * from "./consent";
export * from "./coupon";
export * from "./discount";
export * from "./emailCampaign";
export * from "./general";
export * from "./product";
export * from "./promotion";
export * from "./review";
export * from "./search";
export * from "./session";
export * from "./shippingMethod";

export const SESSION_HEADER_NAME = "x-session-id";
export const FBP_HEADER_NAME = "x-fbp-pixel";
export const FBC_HEADER_NAME = "x-fbc-pixel";
export const REFERER_HEADER_NAME = "x-referer";
