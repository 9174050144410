export const staticLinks = {
  pages: {
    regulations: {
      regulamin: "/regulamin",
      politykaPrywatnosci: "/polityka-prywatnosci",
      dostawaPlatnosci: "/dostawa-platnosci",
      zwrotyReklamacje: "/zwroty-reklmacje",
      regulaminBonu: "/regulamin-bonu",
      regulaminNewsletter: "/regulamin-newsletter",
    },
    staticPages: {
      kontakt: "/kontakt",
      blog: "/blog",
      sklep: "/sklep",
      oNas: "/o-nas",
      b2b: "/wspolpraca-b2b",
      // findUs: "/gdzie-nas-znalezc",
      odbiorOsobisty: "/odbior-osobisty",
      warsztaty: "/warsztaty",
      dopasujKosmetyk: "/dopasuj-kosmetyk",
      search: "/search",
      gwiazdka2024: "/gwiazdka",
      praca: "/praca"
    },
  },
  socials: {
    facebook: "https://www.facebook.com/opcjanatura/",
    instagram: "https://www.instagram.com/opcja_natura/",
    youtube: "https://www.youtube.com/@OpcjaNatura-pm6gm",
    tikTok: "https://www.tiktok.com/@opcja_natura"
  },
};
