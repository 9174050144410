import React from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import { staticLinks } from "../../utils/constants/staticLinks";
import { staticCompanyData } from "../../utils/constants/staticCompanyData";
import FooterLink from "./FooterLink";
import { FacebookLogo, InstagramIcon, MastercardLogo, P24Logo, VisaLogo } from "../../assets/icons";
import { theme } from "../../styles/theme/theme";

const Wrapper = styled.footer`
  margin: 0 auto;
  margin-top: ${theme.spaces.margin.horizontal};
  position: relative;
  padding-top: ${theme.spaces.margin.vertical};

  &:before {
    content: "";
    width: 100%;
    position: absolute;
    top: 0;
    height: 1px;
    opacity: 0.9;
    color: ${theme.colors.secondary};
    border: 1px solid ${theme.colors.secondary_opacity};
  }

  .footer_image {
    position: absolute !important;
    width: 40%;
    bottom: 0;
    z-index: -10;

    @media (min-width: 1200px) {
      width: 20%;
    }
  }

  .footer_right {
    right: 0;
  }

  .footer_left {
    left: 0;
  }
`;

const ContentSection = styled.section`
  padding: 0.8em;
  display: grid;
  grid-template-rows: auto;
  grid-template-areas:
    "text"
    "findus"
    "info"
    "client"
    "company";
  row-gap: ${theme.spaces.margin.vertical};

  @media (min-width: 340px) {
    column-gap: 2em;
    grid-template-areas:
      "text text"
      "findus info"
      "client company";
  }

  @media (min-width: 430px) {
    column-gap: 5em;
  }

  @media (min-width: 1200px) {
    max-width: 1360px;
    margin: 0 auto;
    column-gap: 10em;
    grid-template-areas:
      "findus info client company"
      "text text text text";
  }

  @media (min-width: 1360px) {
    padding: ${theme.spaces.padding.base} 0;
  }

  h3,
  li {
    text-transform: uppercase;
  }

  h3 {
    font-weight: ${theme.fonts.fontWeight.light};
    font-size: ${theme.fonts.fontSize.xl};
  }

  li {
    font-weight: ${theme.fonts.fontWeight.light};
    font-size: ${theme.fonts.fontSize.base};
  }
`;

const LinkContainer = styled.ul`
  display: grid;
`;

const Text = styled.article`
  width: 100%;
  grid-area: text;

  p {
    text-align: justify;
    text-justify: inter-word;
    line-height: 1.8;
  }

  .social-icons {
    margin-top: ${theme.spaces.margin.big};
    display: flex;
    float: right;

    li {
      padding-left: 3em;
      fill: ${theme.colors.secondary};
    }
  }
`;

const FindUs = styled.div`
  grid-area: findus;
  justify-content: center;
`;
const Info = styled.div`
  grid-area: info;
  justify-content: center;
`;
const Client = styled.div`
  grid-area: client;
  justify-content: center;
`;
const CompanyData = styled.div`
  grid-area: company;
  justify-content: center;

  li {
    padding-top: ${theme.spaces.padding.base};
  }
`;

const Creditenials = styled.div`
  margin-top: ${theme.spaces.margin.horizontal};
  padding: 0 ${theme.spaces.padding.base};
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (min-width: 1200px) {
    max-width: 1360px;
    margin: 6em auto;
    margin-bottom: 0;
  }

  .payment-icons {
    display: flex;
    align-items: center;

    li {
      padding-left: ${theme.spaces.padding.base};
    }
  }
`;

const Footer = () => {
  const {
    pages: {
      regulations: { regulamin, politykaPrywatnosci, dostawaPlatnosci, zwrotyReklamacje, regulaminBonu, regulaminNewsletter },
      staticPages: { kontakt, odbiorOsobisty, b2b, blog, praca, oNas },
    },
    socials: { facebook, instagram },
  } = staticLinks;

  const { current } = staticCompanyData;

  const more = [
    { url: kontakt, title: "kontakt" },
    { url: blog, title: "blog" },
    { url: b2b, title: "Współpraca B2B" },
    { url: praca, title: "praca" },
  ];
  const info = [
    { url: regulamin, title: "Regulamin sklepu" },
    { url: politykaPrywatnosci, title: "Polityka prywatności" },
    { url: regulaminBonu, title: "Regulamin bonu" },
    { url: regulaminNewsletter, title: "Regulamin newsletter" },
  ];
  const support = [
    { url: odbiorOsobisty, title: "Odbiór osobisty" },
    { url: dostawaPlatnosci, title: "Dostawa, płatność" },
    { url: zwrotyReklamacje, title: "Zwroty, reklamacje" },
    { url: oNas, title: "o nas" },
  ];

  const paymentIcons = [MastercardLogo, P24Logo, VisaLogo];
  return (
    <Wrapper>
      <ContentSection>
        <Text>
          <h3>słów kilka o opcji natura</h3>
          <p>
            Naszymi kosmetykami chcemy zainspirować do kontaktu z Naturą. Pakujemy ją w słoiczki i buteleczki i kochamy to co robimy. Kosmetyki naturalne jakie produkujemy to prostota i skuteczność w
            jednym. Wszystko to ma zarazić Ciebie do korzystania z Natury. Jest ona piękna, dlatego nasze kosmetyki ubieramy w ręcznie malowane etykiety. W słoiczkach ukrywamy najcenniejsze składniki,
            dobrane tak, aby działały. Pamiętaj - Natura. To proste!
          </p>
          <ul className="social-icons">
            <li>
              <a href={facebook} target="_blank" rel="noreferrer" aria-label="Nasz Facebook">
                <FacebookLogo />
              </a>
            </li>
            <li>
              <a href={instagram} target="_blank" rel="noreferrer" aria-label="Nasz Instagram">
                <InstagramIcon />
              </a>
            </li>
          </ul>
        </Text>
        <FindUs>
          <h3>więcej</h3>
          <LinkContainer>
            {more.map((link, i) => {
              return (
                <li key={i}>
                  <FooterLink url={link.url} title={link.title} />
                </li>
              );
            })}
          </LinkContainer>
        </FindUs>
        <Info>
          <h3>informacje</h3>
          <LinkContainer>
            {support.map((link, i) => {
              return (
                <li key={i}>
                  <FooterLink url={link.url} title={link.title} />
                </li>
              );
            })}
          </LinkContainer>
        </Info>
        <Client>
          <h3>wsparcie klienta</h3>
          <LinkContainer>
            {info.map((link, i) => {
              return (
                <li key={i}>
                  <FooterLink url={link.url} title={link.title} />
                </li>
              );
            })}
          </LinkContainer>
        </Client>
        <CompanyData>
          <h3>dane firmy</h3>
          <LinkContainer>
            <li>{current.name}</li>
            <li>
              {current.adress}, {current.zip} {current.city}
            </li>
            <li>NIP: {current.vatNo}</li>
            <li>{current.mail}</li>
            <li>
              {" "}
              <a href={`tel:${current.phone}`}>tel.: {current.phone}</a>
            </li>
          </LinkContainer>
        </CompanyData>
      </ContentSection>
      <Creditenials>
        <small>Opcja Natura &copy; 2024</small>
        <ul className="payment-icons">
          {paymentIcons.map((Icon, i) => {
            return (
              <li key={i}>
                <Icon />
              </li>
            );
          })}
        </ul>
      </Creditenials>
      <StaticImage className="footer_image footer_right" src="../../assets/images/stopka/plama2_stopka.jpg" alt="opcja natura kosmetyki" />
      <StaticImage className="footer_image footer_left" src="../../assets/images/stopka/plamy_stopka.jpg" alt="opcja natura kosmetyki" />
    </Wrapper>
  );
};

export default Footer;
