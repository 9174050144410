import React from "react";
import { StaticWrapper, ContentWrapper, Bold, ContentParagraph, InParagraphLink } from "./promotionsHelpers";

const ManualPromotion = () => {
  return (
    <StaticWrapper $color="#cfcfcf">
      <ContentWrapper >
        <ContentParagraph color="#000">
          <Bold> 💥 NOWOŚĆ 💥 </Bold> BIOŻEL do higieny intymnej <InParagraphLink to="/sklep/biozel-naturalny-zel-intymny/">sprawdź!</InParagraphLink></ContentParagraph>
      </ContentWrapper>
    </StaticWrapper>
  );
};

export default ManualPromotion;
